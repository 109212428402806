<template>
    <properties-block v-bind="$props" v-on="$listeners">
        <h4 v-tr>Divider|Séparateur</h4>

        <b-form-group>
            <input-color button block v-model="block.color" use-css-var :label="'Color|Couleur'|tr"/>
        </b-form-group>

        <b-form-group label-cols="4" :label="'Size|Taille'|tr">
            <b-input-group>
                <b-select v-model="block.width" :title="'Width|Largeur'|tr">
                    <option value="100%" v-tr>Fill|Remplir</option>
                    <option v-for="n in 50" :key="`width-${n}`" :value="`${n*5}px`">{{n*5}}px</option>
                </b-select>
                <b-select v-model="block.height" :title="'Height|Hauteur'|tr">
                    <option value="none" v-tr>None|Sans</option>
                    <option v-for="n in 50" :key="`height-${n}`" :value="`${n}px`">{{n}}px</option>
                </b-select>
            </b-input-group>
        </b-form-group>

        <b-form-group label-cols="4" :label="'Rounded|Arrondi'|tr">
            <b-select v-model="block.borderRadius">
                <option value="0" v-tr>None|Sans</option>
                <option v-for="n in 49" :key="`width-${n}`" :value="`${n}px`">{{n}}px</option>
            </b-select>
        </b-form-group>
    </properties-block>
</template>

<script>
import PropertiesBlock from "@/components/editor/properties/blocks/properties-block.vue";

// properties-block-divider #hot-reload-debug
export default {
    name: `properties-block-divider`,
    components: {PropertiesBlock},
    extends: PropertiesBlock
}
</script>

<style lang="scss" scoped>
</style>
